/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import StaticLayout from '../components/StaticLayout';
import './privacy.module.css';

const data = {
  noTitle: [
    {
      id: 1,
      content: `ООО «ФРЕШ +» разработал приложение MoscowFresh в качестве бесплатного мобильного приложения (далее - Приложение). Данное Приложение предоставляется ООО «ФРЕШ +» бесплатно.`,
    },
    {
      id: 2,
      content: `Эта страница используется для информирования лиц, осуществляющих доступ к данному Приложению (далее - Пользователей), о наших правилах сбора, использования и обработки  персональных данных.`,
    },
    {
      id: 3,
      content: `Используя данное Приложение, Пользователь соглашается на сбор и использование информации в соответствии с Политикой в отношении обработки персональных данных. Полученные персональные данные используются исключительно в целях улучшения работы Приложения. Персональные данные не будут использоваться или передаваться кому-либо, кроме случаев, описанных в настоящей Политике конфиденциальности.`,
    },
    {
      id: 4,
      content: `Термины, используемые в настоящей Политике конфиденциальности, имеют те же значения, что и в наших Условиях, которые доступны на MoscowFresh, если иное не определено в настоящей Политике в отношении обработки персональных данных.`,
    },
  ],
  withTitle: [
    {
      id: 1,
      title: 'Сбор и обработка персональных данных',
      items: [
        {
          id: 1,
          content:
            'Для более удобного использования Приложения необходимо, чтобы Пользователь предоставил  определенную личную информацию. Данная информация будет сохранена и использована, как описано в настоящей Политике в отношении обработки персональных данных.',
        },
        {
          id: 2,
          content:
            'Приложение использует сторонние сервисы, которые могут собирать информацию для  идентификации Пользователя.',
        },
        {
          id: 3,
          content: 'Ссылки на политику конфиденциальности сторонних поставщиков услуг, используемых приложением',
        },
        {
          id: 4,
          content: (
            <Fragment>
              <ul styleName="list">
                <li>
                  <a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">
                    Сервисы Google Play
                  </a>
                </li>
                <li>
                  <a href="https://firebase.google.com/policies/analytics" rel="noopener noreferrer" target="_blank">
                    Firebase Analytics
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/about/privacy" rel="noopener noreferrer" target="_blank">
                    Facebook
                  </a>
                </li>
              </ul>
            </Fragment>
          ),
        },
      ],
    },
    {
      id: 2,
      title: 'Файл регистрации',
      items: [
        {
          id: 1,
          content:
            'Во время использования Приложения, в случае возникновения ошибки происходит сбор данных и информации (через сторонние продукты) на мобильном устройстве Пользователя под названием Log Data. Эти данные журнала могут включать в себя такую   информацию, как адрес интернет-протокола устройства Пользователя («IP»), имя устройства, версия операционной системы, конфигурация приложения при использовании Приложения, время и дата использования Приложения, а также другие статистические данные. ',
        },
      ],
    },
    {
      id: 3,
      title: 'Cookie',
      items: [
        {
          id: 1,
          content:
            'Файлы «cookie» - это файлы с небольшим объемом данных, которые обычно используются в качестве анонимных уникальных идентификаторов. Они отправляются в браузер Пользователя с посещаемых веб-сайтов и хранятся во внутренней памяти мобильного устройства.',
        },
        {
          id: 2,
          content:
            'Приложение не использует файлы «cookie» напрямую. Тем не менее, Приложение может использовать сторонний код и библиотеки, которые используют файлы «cookie» для сбора информации и улучшения своих услуг. У Пользователя есть возможность принять или отклонить  файлы «cookie» и узнать, когда файл «cookie» отправляется на мобильное устройство. Если Пользователь решит отказаться от сбора файлов «cookie», использование некоторых услуг не представляешься возможным.',
        },
      ],
    },
    {
      id: 4,
      title: 'Поставщики услуг',
      items: [
        {
          id: 1,
          content: 'Сотрудничество со сторонними компаниями и частными лицами осуществляется по следующим причинам:',
        },
        {
          id: 2,
          content: (
            <Fragment>
              <ul styleName="list">
                <li>Для облегчения работы Приложения;</li>
                <li>Предоставлять Приложение от нашего имени;</li>
                <li>Для оказания Приложению сопутствующих услуг;</li>
                <li>Для оказания помощи в анализе работы Приложения.</li>
              </ul>
            </Fragment>
          ),
        },
        {
          id: 3,
          content:
            'Третья сторона имеет доступ к персональным данным Пользователя в связи с необходимостью   выполнять порученные задачи от имени ООО «ФРЕШ +». Обращаем внимание, что третья сторона  не имеет право использовать персональные данные для каких-либо других целей.',
        },
      ],
    },
    {
      id: 5,
      title: 'Безопасность',
      items: [
        {
          id: 1,
          content:
            'ООО «ФРЕШ +» стремиться использовать коммерчески приемлемые способы защиты персональных данных. Однако ни один метод передачи данных через Интернет или метод электронного хранения не является на 100% безопасным и надежным. В этой связи ООО «ФРЕШ +» не может гарантировать абсолютную безопасность персональных данных.',
        },
      ],
    },
    {
      id: 6,
      title: 'Ссылки на сторонние сайты',
      items: [
        {
          id: 1,
          content:
            'Приложение может содержать ссылки на сторонние сайты. Если Пользователь нажмёт на стороннюю ссылку, он будет перенаправлен на данный сайт. Обращаем внимание, что сторонние сайты не находятся под управлением ООО «ФРЕШ +». В этой связи настоятельно рекомендуем ознакомиться с политикой конфиденциальности веб-сайтов. ООО «ФРЕШ +» не несёт ответственности за содержание, политику конфиденциальности или действия любых сторонних сайтов или служб.',
        },
      ],
    },
    {
      id: 7,
      title: 'Детская конфиденциальность',
      items: [
        {
          id: 1,
          content:
            'Данное Приложение не предназначено для лиц младше 13 лет. ООО «ФРЕШ +» сознательно не занимается сбором личной информации лиц младше 13 лет. В случае обнаружения персональных данных лица в возрасте до 13 лет, данная информация будет немедленно удалена. Если вы являетесь родителем или опекуном и знаете, что ваш ребенок предоставил личную информацию, пожалуйста, сообщите об этом для принятия необходимых действий.',
        },
      ],
    },
    {
      id: 8,
      title: 'Изменение Политики в отношении обработки персональных данных',
      items: [
        {
          id: 1,
          content:
            'В Политику в отношении обработки персональных данных могут быть внесены изменения. В этой связи рекомендуем периодически просматривать данную страницу на предмет наличия изменений. Пользователь будет проинформирован о любых изменениях в Политике в отношении обработки персональных данных на данной странице. Любые изменения вступают в силу сразу после их публикации на данной странице.',
        },
      ],
    },
    {
      id: 9,
      title: 'Контакты',
      items: [
        {
          id: 1,
          content: (
            <Fragment>
              Если у вас есть какие-либо вопросы или предложения относительно данной Политики в отношении обработки
              персональных данных, вы можете обратиться к нам по электронному адресу{' '}
              <a href="mailto:info@moscowfresh.ru">info@moscowfresh.ru</a>.
            </Fragment>
          ),
        },
      ],
    },
  ],
};

const PrivacyPolicy = () => (
  <StaticLayout>
    <Helmet title="Политика в отношении обработки персональных данных" />
    <div styleName="wrapper">
      <h1 styleName="h1">Политика в отношении обработки персональных данных</h1>
      <div styleName="block">
        {data.noTitle.map(t => (
          <div styleName="text-block" key={t.id}>
            {t.content}
          </div>
        ))}
      </div>
      {data.withTitle.map(t => (
        <div styleName="block" key={t.id}>
          <div styleName="text-block">
            <strong>{t.title}</strong>
          </div>
          {t.items.map(c => (
            <div styleName="text-block" key={c.id}>
              {c.content}
            </div>
          ))}
        </div>
      ))}
    </div>
  </StaticLayout>
);

export default PrivacyPolicy;
